import styled from "styled-components";

const NavWrapper = styled.nav`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 80px;

  @media screen and (min-width: 280px) and (max-width: 720px) {
  }
`;

export default NavWrapper;
