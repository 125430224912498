import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log("rendered");
  });

  return <App />;
}

const container = document.getElementById("root");

if (container != null) {
  const root = createRoot(container);
  root.render(<AppWithCallbackAfterRender />);
}
