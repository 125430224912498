import NavWrapper from "./NavBar.styles";

const NavBar = () => {
  return (
    <NavWrapper className="navbar navbar-dark shadow px-3">
      <h1 className="navbar-brand col-sm-3 col-md-2 mr-0">Dapp</h1>
      <ul className="navbar-nav flex-row ">
        <li className="px-2">
          <small>Account</small>
        </li>
        <li className="px-2">
          <small>Test</small>
        </li>
      </ul>
    </NavWrapper>
  );
};

export default NavBar;
